<template>
  <validation-observer
    ref="categoryObserver"
    slim
  >
    <div class="mt-2 container-fluid">
      <b-row class="p-1">
        <b-col
          cols="4"
          class="pr-1"
        >
          <b-row>
            <b-col>
              <label class="field-name-class">
                {{ $t("views.playground.index.photo") }}
              </label>
              <div class="product-box">
                <div class="product-img">
                  <img
                    :src="categoryPhoto"
                    class="option-img"
                  >
                  <div class="product-hover">
                    <ul>
                      <li
                        v-if="categoryPhoto === svgFilePath"
                        class="pointer"
                        @click="uploadPhoto"
                      >
                        <button class="btn" type="button">
                          <i class="icon-upload"></i>
                        </button>
                      </li>
                      <li
                        v-else
                        class="pointer"
                        @click="resetPhoto"
                      >
                        <button
                          class="btn"
                          type="button"
                        >
                          <i class="icon-trash"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="8">
          <b-row>
            <b-col cols="6">
              <label class="field-name-class">
                {{ $t('views.playground.index.parameters.categories.form.name') }}
              </label>
            </b-col>
            <b-col cols="6">
              <label class="field-name-class">
                {{ $t('views.playground.index.parameters.categories.form.type') }}
              </label>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <d-text-field
                v-model="category.name"
                :rules="validation.name"
                size="sm"
                :class-name="'background-light-blue-inputs'"
                error-text="validation.text-input-three-characters"
              />
            </b-col>
            <b-col cols="6">
              <select
                v-model="category.activityType"
                class="form-control background-light-blue-inputs"
                size="sm"
              >
                <option value="sport">
                  {{ $t('components.form.playground.block-price.activityType.sport') }}
                </option>
                <option value="leisure">
                  {{ $t('components.form.playground.block-price.activityType.leisure') }}
                </option>
                <option value="lesson">
                  {{ $t('components.form.playground.block-price.activityType.lesson') }}
                </option>
                <option value="formula">
                  {{ $t('components.form.playground.block-price.activityType.formula') }}
                </option>
              </select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="border-bottom mt-3"></div>
      <b-row class="mt-3">
        <b-col align="right">
          <d-button
            v-if="category.id"
            text="general.actions.cancel"
            class="width-max-content d-btn-sm btn d-btn-default font-text-title mr-2"
            @on:button-click="onCancel"
          />

          <d-button
            :text="category.id ? 'general.actions.edit' : 'views.playground.index.parameters.categories.new'"
            class="width-max-content d-btn-sm btn d-btn-danger font-text-title"
            @on:button-click="onValidate"
          />
        </b-col>
      </b-row>
    </div>
  </validation-observer>
</template>

<script>
import {_delete} from "@api/doinsport/services/httpService";
import BlockPriceCategory from "@/classes/doinsport/BlockPriceCategory";
import {
  BLOCK_PRICE_CATEGORY_URI, postBlockPriceCategory,
  putBlockPriceCategory
} from "@api/doinsport/services/timetable/blocks/price/category/category.api";
import {postBlockPriceParticipantCategory} from "@api/doinsport/services/timetable/blocks/price/participant-category/participant-category.api";

export default {
  data: () => ({
    fileToken: null,
    validation: require('@validation/entities/doinsport/Option.json')
  }),
  props: {
    category: {
      type: Object,
      default: () => new BlockPriceCategory()
    }
  },
  computed: {
    svgFilePath() {
      return require('@/assets/icons/playground/new/step-1/upload-image.svg');
    },
    categoryPhoto() {
      if (this.category.id) {
        return this.category.photo ? this.$filePath(this.category.photo.contentUrl) : this.svgFilePath;
      } else {
        return this.fileToken ? this.fileToken : this.svgFilePath;
      }
    }
  },
  methods: {
    uploadPhoto() {
      if (this.category.id === null) {

        this.$upload(this.uploaded,
          {
            entry: 'category',
            url: null,
            target: 'blockPriceCategoryPhoto'
          })
        ;
      } else {
        this.$upload(this.uploaded, {
          entry: 'category',
          url: BLOCK_PRICE_CATEGORY_URI + '/' + this.category.id,
          target: 'blockPriceCategoryPhoto'
        });
      }
    },
    uploaded(file) {
      if (this.category.id === null) {
        this.fileToken = URL.createObjectURL(file);
        this.category.photo = file;
      } else {
        this.fileToken = this.$filePath(file.contentUrl);
        this.category.photo = file;
      }
    },
    resetPhoto() {
      this.fileToken = null;

      if (this.category.id) {
        _delete(this.category.photo['@id'])
          .then(() => this.category.photo = null)
        ;
      } else {
        this.category.photo = null;
      }
    },
    onCancel() {
      this.$emit('reset:category-form');
      this.resetObserver();
    },
    onValidate() {
      try {
        this.$refs.categoryObserver.validate().then((valid) => {
          if (valid) {
            const serializedCategory = new BlockPriceCategory(this.category, {serialize: true});

            delete serializedCategory.photo;

            this.getOptionRequest(serializedCategory)
              .then((response) => {
                if (null !== this.category.photo && this.category.id === null) {
                  const payload = {entry: 'category', url: response.data['@id'], target: 'blockPriceCategoryPhoto'};
                  this.$uploadFile(this.category.photo, payload);
                }

                this.$emit('on:category-updated');
                this.$vs.notify({
                  time: 5000,
                  title: this.$t('general.actions.notification'),
                  text: this.$t('views.playground.index.parameters.categories.added'),
                  color: 'success',
                  position: 'top-right'
                });
              }).finally(
              () => {
                this.$emit('reset:category-form');
                this.resetObserver();
              })
            ;
          }
        })
      } catch (err) {
      }
    },
    getOptionRequest(category) {
      return category.id ? putBlockPriceCategory(category) : postBlockPriceCategory(category);
    },
    resetObserver() {
      this.$refs.categoryObserver.reset();
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/ .input-group-text {
  line-height: 0;
}

.option-img {
  width: 280px;
  height: 110px;
  object-fit: cover;
}

.product-box .product-img {
  .product-hover {
    width: 280px;
    height: 110px;
  }
}
</style>
